<template>
  <div class="form-group mb-0">
    <label v-if="label" class="form-label">{{ label }}</label>
    <div
      class="d-flex my-1"
      data-toggle="buttons"
      :class="{
        'flex-column': column,
        'align-items-start': start,
        'align-items-center': !start
      }"
    >
      <label
        v-for="type in options"
        :key="type.value"
        :class="
          `btn d-flex align-items-center pl-2 pr-3 ${
            current === type.value ? 'selected' : ''
          }`
        "
      >
        <input
          class="mr-2"
          :style="{ width: '16px', height: '16px' }"
          name="types"
          type="radio"
          :value="type.name"
          :checked="current === type.value"
          @change="handleClick"
        />
        {{ formatName(type) }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String
    },
    current: {
      type: Number,
      required: true
    },
    column: {
      type: Boolean
    },
    start: {
      type: Boolean
    }
  },

  data() {
    return {};
  },

  computed: {},

  methods: {
    handleClick(e) {
      const [x] = this.options.filter(type => type.name === e.target.value);
      this.$emit("input", x.value);
      this.$emit("dropgrp");
    },

    formatName(type) {
      let name = "";
      switch (type.value) {
        case 2:
        case 3:
        case 5:
          name = type.name.charAt(0).toUpperCase() + type.name.slice(1);
          break;
        case 4:
          name = type.name.slice(0, -1) + type.name.charAt(1).toUpperCase();
          break;
        default:
          name = type.name.charAt(0).toUpperCase() + type.name.slice(1);
          break;
      }

      return name;
    }
  }
};
</script>
